import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  number,
  shape,
  string,
  bool,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  Divider, Flex, Icon, TabsContainer,
} from '@components';
import { ReactComponent as KlarnaMarketingBadge } from '@assets/KlarnaMarketingBadge.svg';
import { STORE_NAMES } from '@constants';
import { useWindowSize } from '@hooks/useWindowSize';
import { showErrorToast } from 'src/utils/helpers';
import { PayWithCardTab, PayWithKlarnaTab } from './tabs';
import EmailForm from './EmailForm/EmailForm';
import { EMAIL_FORM_VARIANTS } from './EmailForm/EmailForm.layout';
import { createCustomer, getCustomerByEmail } from '../../../../store/customer/duck';
import { trackGTMBeginCheckout } from './helpers';
import { getContainerStyles } from './styles';

const PaymentBlock = ({ subscription, isKlarnaDisabled }) => {
  const { t } = useTranslation();
  const { isDesktop } = useWindowSize();

  const { authorization_info } = useSelector(store => store[STORE_NAMES.AUTH]);
  const { customer } = useSelector(store => store[STORE_NAMES.CUSTOMER]);
  const dispatch = useDispatch();

  const [emailValue, setEmailValue] = useState('');
  const [emailFormVariant, setEmailFormVariant] = useState(EMAIL_FORM_VARIANTS.DEFAULT);

  useEffect(() => {
    if (authorization_info?.email) {
      setEmailValue(authorization_info?.email);
      setEmailFormVariant(EMAIL_FORM_VARIANTS.REGISTERED);
      dispatch(getCustomerByEmail({ email: authorization_info?.email }));
    }
  }, [authorization_info?.email]);

  const trackInitiateCheckoutFunction = ({ sub, promotionProps = {} }) => {
    trackGTMBeginCheckout({ sub, promotionProps, authorization_info });
  };

  const handleEmailChange = e => {
    setEmailValue(e.target.value);
  };

  const handlePaymentSubmit = async () => {
    if (customer?.id) return { customer };

    const newCustomer = await dispatch(createCustomer({ email: emailValue }));

    if (newCustomer.payload.data?.error) {
      showErrorToast(newCustomer.payload.data.error.message);
      return { customer: null };
    }

    dispatch(getCustomerByEmail({ email: emailValue }));

    return { customer: newCustomer.payload.customer };
  };

  const tabs = [
    {
      title: t('GetSubscription.Card'),
      id: 1,
      component: (
        <PayWithCardTab
          emailValue={emailValue}
          onSubmit={handlePaymentSubmit}
          subscription={subscription}
          trackInitiateCheckout={trackInitiateCheckoutFunction}
          isFormDisabled={emailFormVariant === EMAIL_FORM_VARIANTS.DEFAULT}
        />
      ),
    },
    {
      title: <Icon width={60} SVG={KlarnaMarketingBadge} />,
      id: 2,
      component: (
        <PayWithKlarnaTab
          subscription={subscription}
          disabled={isKlarnaDisabled}
          trackInitiateCheckout={trackInitiateCheckoutFunction}
          isFormDisabled={emailFormVariant === EMAIL_FORM_VARIANTS.DEFAULT}
          onSubmit={handlePaymentSubmit}
        />
      ),
      disabled: isKlarnaDisabled,
      tooltipText: t('GetSubscription.Klarna.Tooltip'),
    },
  ];

  const handleVariantChange = variant => {
    setEmailFormVariant(variant);
  };

  return (
    <Flex column {...getContainerStyles(isDesktop)}>
      <EmailForm
        variant={emailFormVariant}
        value={emailValue}
        onChange={handleEmailChange}
        onVariantChange={handleVariantChange}
      />
      <Divider style={{ margin: '20px 0' }} />
      <TabsContainer
        tabs={tabs}
        mainContainerStyles={{
          marginTop: 0,
          borderRadius: 'sm',
        }}
        tabItemsContainerStyles={{ width: '100%', flex: 0 }}
      />
    </Flex>
  );
};

PaymentBlock.propTypes = {
  subscription: shape({
    id: number.isRequired,
    price: number.isRequired,
    price_after_first_payment: number.isRequired,
    duration: number.isRequired,
    title: string.isRequired,
    title_no: string.isRequired,
    has_chat: bool.isRequired,
    has_diet_plan: bool.isRequired,
    has_training_program: bool.isRequired,
    has_own_training_program: bool.isRequired,
    has_coach: bool.isRequired,
    has_coaching: bool.isRequired,
    has_booklets: bool.isRequired,
    has_recipes_db: bool.isRequired,
  }),
  isKlarnaDisabled: bool.isRequired,
};

PaymentBlock.defaultProps = {
  subscription: {},
};

export default PaymentBlock;
