/* eslint-disable consistent-return */
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  INITIAL_STATE,
  PENDING_STATE,
  REJECTED_STATE,
  FULFILLED_STATE,
  STORE_NAMES,
} from '@constants';

import { createCommonAsyncThunk } from '@utils/helpers';
import api from './api';

const { CUSTOMER } = STORE_NAMES;

const initialState = {
  ...INITIAL_STATE,
  customer: null,
};

const getCustomerByEmailThunkName = `${CUSTOMER}/getCustomerByEmail`;
const createCustomerThunkName = `${CUSTOMER}/createCustomer`;

export const getCustomerByEmail = createCommonAsyncThunk(
  getCustomerByEmailThunkName,
  api.getCustomerByEmail,
);

export const createCustomer = createCommonAsyncThunk(
  createCustomerThunkName,
  api.createCustomer,
);

const customerSlice = createSlice({
  name: CUSTOMER,
  initialState,
  reducers: {
    resetState: () => initialState,
    resetError: state => ({
      ...state,
      error: false,
    }),
  },
  extraReducers: builder => {
    builder
      .addMatcher(isAnyOf(createCustomer.fulfilled, getCustomerByEmail.fulfilled), (state, action) => {
        const { customer } = action.payload;

        return {
          ...state,
          ...FULFILLED_STATE,
          customer: {
            ...customer,
            email: action?.meta?.arg?.email || state?.customer?.email,
          },
        };
      })
      .addMatcher(isAnyOf(
        getCustomerByEmail.pending,
        createCustomer.pending,
      ), state => {
        if (!state.isPending) {
          return {
            ...state,
            ...PENDING_STATE,
          };
        }
      })
      .addMatcher(
        isAnyOf(
          getCustomerByEmail.rejected,
          createCustomer.rejected,
        ),
        (state, action) => {
          const {
            data: { message, errors },
            status,
          } = action.payload;

          if (state.isPending) {
            const newState = {
              ...state,
              ...REJECTED_STATE,
              error: { message, status, errors },
            };

            return newState;
          }
        },
      );
  },
});

const customerPersistConfig = {
  key: CUSTOMER,
  storage: AsyncStorage,
};

const { actions: customerActions, reducer: customerReducer } = customerSlice;

export { customerActions, customerReducer, customerPersistConfig };
